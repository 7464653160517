.amenities .card-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 160px;
  transform: scale(0.8);
  opacity: 0.9;
  border: 2px solid #f1ce3c;
}

.amenities .card-box h5 {
  margin-top: 0.8rem;
}
.amenities .slick-center .card-box {
  transform: scale(1);
  opacity: 1;
  border: 3px solid #f1ce3c;
}

.amenities .card-box svg {
  align-items: center;
  font-size: 40px;
  color: #f1ce3c;
}
.amenities .card-box h6 {
  margin-top: 15px;
}

.design .card-box-img {
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.8);
  opacity: 0.7;
}
.design .card-box-img img {
  border-radius: 7px;
  max-height: 450px;
}

.musings .card-box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  min-height: 450px;
  background: #fff4c6;
  transform: scale(0.8);
  opacity: 0.7;
}

.slick-center {
  opacity: 1;
}

.musings .slick-center .card-box {
  border: 5px solid #f1ce3c;
  transform: scale(1);
  opacity: 1;
}
.design .slick-center .card-box-img {
  border: 5px solid #f1ce3c;
  transform: scale(1);
  opacity: 1;
}

.slick-next,
.slick-prev {
  padding-left: 1px !important ;
  padding-top: 4px !important;
}

.home .slick-next {
  right: 30px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.home .slick-prev {
  left: 30px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}
.musings .slick-next {
  right: 180px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}
.musings .slick-prev {
  left: 180px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}
.design .slick-next {
  right: 180px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}
.design .slick-prev {
  left: 180px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}
.amenities .slick-next {
  right: -50px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}
.amenities .slick-prev {
  left: -50px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
}

@media (max-width: 768px) {
  .design .slick-next {
    right: 5px !important;
  }
  .design .slick-prev {
    left: 5px !important;
  }
  .home .slick-next {
    right: 5px !important;
  }
  .home .slick-prev {
    left: 5px !important;
  }
  .musings .slick-next {
    right: 5px !important;
  }
  .musings .slick-prev {
    left: 5px !important;
  }
  .amenities .slick-next {
    right: 5px !important;
  }
  .amenities .slick-prev {
    left: 5px !important;
  }
}

@media screen and (max-width: 450px) {
  .card-box {
    /* margin-bottom: 50px; */
    height: max-content;
  }
  .card-box h6 {
    font-size: 10px;
  }
  .house-types p {
    font-size: 0.7rem;
    padding: 6px 10px;
    min-width: auto;
  }
}
