p {
  font-size: 1.1rem;
}

h1 {
  font-size: 2.8rem;
}
h3 {
  font-size: 2.4rem;
}
h4 {
  font-size: 2rem;
}

.header-routes {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}
.header-routes li {
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-cont-ftr {
  width: 100%;
}
.logo-img {
  width: 15rem;
}
.logo-img-header {
  width: 18rem;
}
.logo-img-two {
  width: 16rem;
  margin-top: 3rem;
}
.two-containers {
  display: flex;
  margin-top: -2rem;
  justify-content: space-around;
  margin-bottom: 1rem;
}
.logo-cont {
  height: 12rem;
  margin-top: -130px;
  margin-bottom: 10px;
}
/* home page */
.logo-cont-2 {
  height: 12rem;
  margin-top: -110px;
}
.home {
  height: calc(100vh - 70px);
}
.home .home-img img {
  height: calc(100vh - 100px);
  width: 100%;
}

.home-text {
  display: flex;
  justify-content: center;
  align-items: start;
  height: inherit;
  flex-direction: column;
  width: 80%;
  padding: 20px 20%;
  text-align: left;
}

.btn-yellow {
  border-radius: 18px;
  background: #f2d03a;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  border-radius: 5px;
  font-weight: 550;
  width: max-content;
  color: #000000;
  padding: 15px 30px;
  box-shadow: 0px 10px -9px 0px rgba(192, 192, 192, 0.35);
}

.home-sec-2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.home-sec-3 {
  display: flex;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 5rem 0;
  flex-wrap: wrap;
}
.home-sec-3-bg {
  background: #f1ce3c;
  width: 100%;
  height: 150px;
  z-index: -1;
  margin-bottom: 100px;
  position: absolute;
}
.bg-yellow-head {
  background: #f1ce3c;
  height: 80px;
}
.home-sec-3::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.home-sec-3 .sec-3-1 {
  padding: 0 30px;
  background: #ffffff;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 10px;
  width: 225px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.home-sec-3 .sec-3-1 p {
  font-size: 1.3rem;
}
.home-sec-3-poster {
  background-image: url("../Images/FaqPoster.png");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.poster-text {
  display: flex;
  height: 250px;

  align-items: end;
  justify-content: center;
}
.home-sec-3-poster .poster-text,
.home-sec-4-poster .poster-text,
.home-sec-7-poster .poster-text,
.home-sec-8-poster .poster-text,
.home-sec-9-poster .poster-text,
.home-sec-12-poster .poster-text,
.home-sec-12-poster .poster-text {
  height: 250px;
}
.poster-text h3 {
  border-radius: 37px 37px 0px 0px;
  background: #f1ce3c;
  padding: 20px 45px;
}
.modal-title {
  /* background-color: pink; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 4rem;
}
.modal-btn {
  border-radius: 5px;
}
.logo-img-modal {
  width: 18rem;
  position: absolute;
}
.home-sec-4 {
  margin: auto;
  display: flex;
  gap: 30px;
}

.home-sec-4 .explore div {
  padding: 13px 0px;
}
.home-sec-4 .explore .icon {
  background-color: gray;
  padding: 5px;
  border-radius: 50%;
}
.home-sec-4 .explore {
  justify-content: left;
}
.home-sec-4 .poster img {
  height: 70vh;
  /* width: 100%; */
  border-radius: 25px;
}

.home-sec-4-poster {
  background-image: url("../Images/sec-3.png");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.home-sec-5 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.home-sec-6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border-radius: 30px;
  background: #fffbe9;
  align-items: center;
}

.home-sec-6-poster {
  background-image: url("../Images/FaqPoster.png");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-sec-7-poster {
  background-image: url("../Images/FaqPoster.png");
  /* background-image: url("../Images/sec-3.png"); */
  height: 250px;
  /* margin-top: 8rem; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-sec-8-poster {
  background-image: url("../Images/FaqPoster.png");
  height: 250px;
  /* margin-top: 8rem; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.project-card {
  display: flex;
  padding: 0px 8rem;
  align-items: center;
  gap: 30px;
}
.project-card .image {
  width: 100%;
  align-self: flex-start;
  border-radius: 0px 60px 0px 270px;
}

.project-card h1 {
  color: #f1ce3c;
  font-size: 4.5rem;
}

.project-card .icon {
  height: 4.5rem;
}

.home-sec-7 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.house-types {
  display: flex;

  justify-content: space-evenly;
}

.house-types p {
  border: 2px solid #f1ce3c;
  padding: 10px 25px;
  min-width: 120px;
  border-radius: 25px;
}

.home-sec-8 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.home-sec-9-poster {
  background-image: url("../Images/FaqPoster.png");

  height: 250px;
  /* margin-top: 8rem; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-sec-10-poster {
  background-image: url("../Images/FaqPoster.png");

  height: 250px;
  /* margin-top: 8rem; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.home-sec-9 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.yellow-box-hover:hover {
  background: #f2d03a;
  border-radius: 10px;
  transform: scale(1.1);
  transition: all ease-in-out 0.5s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.yellow-box-hover {
  padding: 15px 0px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  width: 31% !important;
  margin: 0 1%;
}

.yellow-box-hover li {
  font-size: 1rem;
}

.home-sec-12 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.home-sec-10 {
  background-image: url("../Images/background-epic-pass.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
}

.home-sec-10 .content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  margin: auto;
}

.home-sec-10 .content img {
  height: 500px;
}

.home-sec-11-poster {
  background-image: url("../Images/passPoster\ \(2\).png");

  height: 250px;
  /* margin-top: 8rem; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-sec-12-poster {
  background-image: url("../Images/FaqPoster.png");

  height: 250px;
  /* margin-top: 8rem; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-sec-13 {
  display: flex;
  margin: auto;
  align-items: flex-start;
  text-align: initial;
  font-size: 1rem;
  background-color: #f1ce3c;
}

.input {
  border: 1px solid #f1ce3c;
  height: 40px;
  width: 100%;
  border-radius: 5px;
}

/* .home-sec-3-parallax {
  height: 100vh;
  overflow-y: auto;
  background: url("../Images/background-scroll-1.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */

/* .home-sec-3-parallax::-webkit-scrollbar {
  display: none;
} */
/* .home-sec-7-parallax {
  height: 100vh;
  overflow-y: auto;
  background: url("../Images/background-scroll-2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.home-sec-7-parallax::-webkit-scrollbar {
  display: none;
} */
/* .home-sec-11-parallax {
  height: 100vh;
  overflow-y: auto;
  background: url("../Images/background-scroll-3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.home-sec-11-parallax::-webkit-scrollbar {
  display: none;
} */

.accordion-button:focus {
  z-index: 3;
  border-color: white !important;
  outline: 0;
  box-shadow: none !important;
}
.accordion-button:active {
  z-index: 3;
  border-color: white !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  /* color: var(--bs-accordion-active-color); */
  background-color: #f2d03a !important;
}

.home-sec-14 {
  background-color: #f1ce3c;
}

.bg-col-white {
  background: #ffffff !important;
  background-color: #ffffff !important;
}

.btn-yellow.btn-black {
  background: #000000;
  color: #ffffff;
  padding: 10px 20px;
  box-shadow: none !important;
}

.work.accordion-header .accordion-button {
  padding: 8px !important;
}

.work p {
  font-size: 0.9rem;
}

.acc-body.accordion-body {
  padding: 0px 10px !important;
  font-size: 1.1rem;
}

.accordion-item {
  padding: 0 !important;
}

.offcanvas {
  width: 250px !important;
}

.header-routes-canvas li {
  list-style: none;
  padding: 20px 10px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
}
.header-routes-canvas a {
  text-decoration: none;
}
.header-routes a {
  text-decoration: none;
  color: black;
}
/* Responsive CSS */

.home-window-img {
  display: block; /* Display Image1 on desktop */
}

.home-mobile-img {
  display: none; /* Hide Image3 on desktop */
}

.mobile-cont {
  display: none;
}
.desktop-cont {
  display: block;
}

.btn-yellow.btn-black.disabled {
  opacity: 0.8;
  background: #1d1d1d;
  cursor: not-allowed;
}

/* thankyou page  */

/* CONFIRMATION PAGE CSS */
.confirm-btn {
  background-color: black;
  color: white;
  border: none;
  outline: none;
  padding: 0.8rem;
  border-radius: 8px;
  width: 250px;
  text-align: center;
  text-decoration: none;
}
.confirm-page-container {
  width: 100%;
  height: 100vh;
  background-color: #f1ce3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.confirm-msg {
  width: 200px;
}

/* section 13 */

.section-13 {
  background: #ffffff;
}

.section-13 h5 {
  color: #ffffff !important;
}
.section-13 h6 span {
  color: #f2d03a !important;
}
.section-13 .musings img {
  width: 100%;
}

.section-13 .box {
  border: 1px solid #f2d03a;
  background: #2e3234;
  border-radius: 5px;
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  padding: 5px;
}
.section-13 .box p {
  color: #ffffff !important;
  text-align: center;
  font-size: 0.8rem !important;
}
@media (max-width: 992px) {
  .home-sec-3 .sec-3-1 {
    margin: 10px;
  }
  .home-sec-3-bg {
    margin-bottom: 225px;
  }
  .project-card {
    padding: 0 2rem;
  }
}
@media (max-width: 768px) {
  .home-sec-3 {
    padding: 0 0 5rem 0;
  }
  .home-sec-3 .sec-3-1 {
    margin: 10px;
  }
  .home-sec-4 .poster,
  .home-sec-4 .explore {
    padding: 0 3rem;
  }
  .home-sec-3 .sec-3-1 {
    min-width: 150px;
    padding: 0px;
  }
  .yellow-box-hover {
    width: 48% !important;
    margin: 0 1%;
  }
  .home-sec-3 .sec-3-1 {
    width: 160px;
  }
  /* .home-window-img {
    display: none;
  }
  .home-mobile-img {
    display: block;
  }
  .mobile-cont {
    display: block;
  }
  .desktop-cont {
    display: none;
  } */
}
@media only screen and (max-width: 767px) {
  /* Adjust the width as needed for your mobile breakpoint */
  .home-window-img {
    display: none; /* Hide Image1 on mobile */
  }
  .home-sec-10 .content img {
    max-height: 34vh;
  }
  .home-sec-10 .content {
    height: 84%;
  }
  .home-sec-10 {
    display: flex;
  }
  .home-sec-3 .sec-3-1 p {
    font-size: 1rem;
  }

  .home-mobile-img {
    display: block; /* Display Image3 on mobile */
  }
  /* .home-sec-3-parallax {
    background: url("../Images/bg1-sm.png");
  }
  .home-sec-7-parallax {
    background: url("../Images/bg2-sm.png");
  }
  .home-sec-11-parallax {
    background: url("../Images/bg4-sm.png");
  } */
  .home-sec-10 {
    background: url("../Images/bg3-sm.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
  }
}
@media (max-width: 450px) {
  .home-text {
    width: 100%;
    padding: 20px 10%;
  }
  .project-card .icon {
    height: 2.5rem;
  }
  .project-card h1 {
    font-size: 2.5rem;
  }
  .project-card {
    gap: 8px;
    padding: 0 15px;
    margin-top: 20px !important;
  }
  .yellow-box-hover {
    width: 80% !important;
    margin: auto;
  }
  .home-sec-4 .poster,
  .home-sec-4 .explore {
    padding: 0 1rem;
  }
  .footer-rera {
    overflow: hidden;
  }
  .btn-yellow {
    font-size: 1rem;
    padding: 10px 20px;
    margin: auto;
  }
  .logo-img-header {
    width: 12rem;
  }
}

.text-size-sm {
  font-size: 0.9rem;
}

.bold {
  font-weight: 550;
}
