.pass-img img{
   height:320px;
   width: 180px;
   padding: 0;

}
.pass-img{
   height:320px;
   width: 180px;
}
.pass-img .code {
   position: absolute;
   top: 288px;
   font-size: 1.5rem;
   font-family: 'Courier New', Courier, monospace;
   /* bottom: 20px; */
   color: #f1ce3c;
   font-weight: 600;
}